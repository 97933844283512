body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(-200deg, #000000, #282828, #000000);
  background-size: 400% 400%;
  animation: gradientAnimation 45s ease infinite;
  height: 100%;
  color: rgb(159, 159, 159);
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* hides scroll bar, but keeps scrolling on main browsers. */
body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  min-height: 100vh; /* Use viewport height to push the footer down */
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: space-between; /* Push footer to the bottom */
}

h1{
  color: #d2d2d2;
}

p{
  color: #9e9e9e;
  font-size: calc(4px + 2vmin);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.yellow-span {
  color: #D8AD16;
}

.custom-navbar {
  background-color: #0a0a0a;
  padding: 10px 0;
  border-bottom: .5px solid #D8AD16;
}

/* Assuming .navbar-brand-custom is the class for your logo */
.navbar-brand-custom, .navbar-brand-custom:visited, .navbar-brand-custom:hover, .navbar-brand-custom:focus, .navbar-brand-custom:active {
  color: #ffffff; 
  text-decoration: none; 
}

.navbar-brand-custom:visited {
  color: #ffffff !important;
}

.navbar-brand-custom:hover {
  color: #fff; 
}

.nav-link-custom {
  color: #aaa;
}

.nav-link-custom:hover {
  color: #fff; 
}

.nav-link-custom:hover{
  color: #ffffff !important; 
  text-decoration: none; 
}

.nav-link-custom:active, .nav-link-custom:focus {
  color: #D8AD16 !important; 
  text-decoration: none; 
}

.custom-toggler span.navbar-toggler-icon {
  border-color: #aaa;
background-color: #D8AD16;
}

.custom-toggler {
  border-color: #d8d8d8;
}

p.text-end {
  color: #c4c4c4;
  /* add dynamic footer text, change font size */
  font-size: calc(4px + 1vmin);
}

footer {
  /* bordertop */
  border-top: 1px solid #ffffff;
  /* background color */
  background-color: #0a0a0a;
  /* bottom of every page without sticking  */
  height: 50px;
}

a.me-2.btn.btn-primary{
  background-color: #D8AD16;
  border-color: #9f800f;
}

a.me-2.btn.btn-primary:hover{
  background-color: #e1c252;
  border-color: #9f800f;
}

a.btn.btn-secondary{
  background-color: #606060;
  border-color: #cbcbcb;
}

a.btn.btn-secondary:hover{
  background-color: #999999;
  border-color: #b2b2b2;
}

img.img-fluid.rounded-circle{
  margin-top: calc(4px + 1vmin);
  border: 2px solid #D8AD16;
}


.product-card {
  /* Your custom styles here */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Example shadow */
  border: none; /* Remove border */
  background-color: #0a0a0a;
}


.product-card .card-title,
.product-card .card-subtitle {
  /* Custom font styling */
  color: #9e9e9e;

}

.product-card .card-img {
  /* Custom styling for the image */
  

}

.product-card .list-group-item {
  background-color: #0a0a0a;
  color: #9e9e9e;
}

.product-card .card-text {

  color: #9e9e9e;

}

.border-custom {
  border: 1px solid #414141;
}


button.btn.btn-primary{
  background-color: #bfa136;
  border-color: #9f800f;
  
}

button.btn.btn-primary:hover{
  background-color: #e1c252;
  border-color: #9f800f;
}


@media screen and (min-width: 992px) {
/* blob background blur effect*/

@keyframes rotate {
  from {
    rotate: 0deg;
  }
  
  50% {
    scale: 1 1.5;
  }
  
  to {
    rotate: 360deg;
  }
}

#blob {
  background-color: white;
  height: 34vmax;
  aspect-ratio: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  border-radius: 50%;
  background: linear-gradient(to right, rgb(255, 163, 4), rgb(74, 0, 117));
  animation: rotate 20s infinite;
  opacity: 0.4;
  z-index: -2;

}

#blur {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0; 
  left: 0; 
  z-index: -1; 
  backdrop-filter: blur(12vmax);
}
}

div.m-O.card-title.h5{
}